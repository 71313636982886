.root .text {
  background-color: transparent;
  padding: 0px;
  border-radius: 0px;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}


.text {
  width: 100%;
  margin-top: 20px;
  text-align: left;
  background-color: #001e2dd6;;
  padding: 30px;
  border-radius: 20px;
  

  &:first-child {
    margin-top: 0;
  }
}




.text p {
  text-align: left;
  margin: 0px;
  padding: 0px;
  color: black;
}

.noStyle{
  background-color: rgb(240, 84, 6);
  padding: 0px;
  border-radius: 0px;
  color:gray;
  transition: .5s;

  &:hover{
    margin: 5px 5px 5px 0px;
    padding: 5px;
    text-decoration: none;
    border-style: solid;
    border-width: thin;
    border-radius: 5px;
  }
}

.header{
  color: white;
}

.heroHeader{
  color: rgb(0, 0, 0);
}